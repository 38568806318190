/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef } from 'react';
import CurrencyInput from 'react-currency-input-field';
import pagoMeses from '../../../../../global/data/pagoMeses.json';
import pagoDia from '../../../../../global/data/diaPago.json';
import { FormikStep3 } from '../../../formik/formikStepsConvert';

const FormStept3 = ({
  saveData, cambiarOpcion, productosL, opcionAnterior, dataClient, dataSaleT
}) => {
  // const isFormValid = (errors) => errors.length === 0;
  const [submitted, setSubmitted] = useState(false);
  const [confirmContract, setConfirmContract] = useState(false);
  const [updateC, setUpdateC] = useState(false);
  const [recalcMont, setRecalcMont] = useState(true);
  const divRef = useRef(null);

  const {
    values,
    handleChange,
    errors,
    setFieldValue,
    isValid
  } = FormikStep3();

  const {
    noContrato,
    noContratoNumerico,
    precioVenta,
    formaPago,
    diaPago,
    mensualidad,
    comentarios,
    anticipo
  } = values;
  const formatMoney = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const handleSubmitStep = (e) => {
    e.preventDefault();
    setSubmitted(true);
    // if (isFormValid(errors)) {
    if (isValid) {
      let tempValues = values;
      if (formaPago === 'mensualidades') {
        tempValues.costoM = CalcMonthsRaw(mensualidad, precioVenta);
        tempValues.plazo = `${mensualidad} X $${CalcMonthsRaw(mensualidad, precioVenta)}`;
      } else {
        tempValues.costoM = precioVenta;
        tempValues.mensualidad = '1';
        tempValues.plazo = `1 X $${precioVenta}`;
        tempValues.diaPago = 0;
      }
      tempValues.noContratoNumerico = tempValues.noContrato;
      tempValues.fecha = new Date().getTime();
      saveData(tempValues);
      cambiarOpcion(tempValues, updateC);
    } else {
      window.scrollTo(0, 0);
    }
  };

  const returStep = () => {
    opcionAnterior();
    saveData(values);
  };

  const calcTotal = () => {
    let tempTotal = 0;
    productosL.forEach((itemPL) => {
      if (itemPL.disponibilidad === 'prevision') {
        tempTotal += (itemPL.cantidad * itemPL.precioPrevision) || 0;
      } else {
        tempTotal += (itemPL.cantidad * itemPL.precio) || 0;
      }
    });
    setFieldValue('precioVenta', dataSaleT);
  };
  const CalcMonthsRaw = (months, precioV) => {
    if (months && precioV) {
      try {
        const tempM = parseInt(months, 10);
        const tempAnt = parseFloat(anticipo) || 200;
        const tempMonth = (parseFloat(precioV) - tempAnt) / tempM;
        return tempMonth.toFixed(2);
      } catch (_error) {
        return 0;
      }
    }
    return 0;
  };
  const CalcMonths = (months, precioV) => {
    if (months && precioV) {
      try {
        const tempM = parseInt(months, 10);
        const tempAnt = parseFloat(anticipo) || 200;
        const tempMonth = (parseFloat(precioV) - tempAnt) / tempM;
        return formatMoney.format(tempMonth);
      } catch (_error) {
        return '$-';
      }
    }
    return '$-';
  };
  const _handleChange = (_ev, _field) => {
    if (_ev) {
      setFieldValue(`${_field}`, _ev);
    } else {
      setFieldValue(`${_field}`, '200');
    }
  };

  useEffect(() => {
    if (productosL) {
      calcTotal();
    }
  }, [productosL]);
  useEffect(() => {
    if (divRef) {
      const container = document.getElementById('FORM-REF');
      container.scrollIntoView({ behavior: 'smooth' });
    }
  }, [divRef]);
  useEffect(() => {
    if (anticipo) {
      setRecalcMont(false);
      setTimeout(() => {
        setRecalcMont(true);
      }, 1);
    }
  }, [anticipo]);
  useEffect(() => {
    setFieldValue('formaPago', 'mensualidades');
  }, []);

  useEffect(() => {
    if (formaPago && formaPago === 'contado') {
      setFieldValue('anticipo', precioVenta);
    } else {
      setFieldValue('anticipo', 200);
    }
  }, [formaPago]);

  useEffect(() => {
    if (dataClient && dataClient._id) {
      let blockData = true;
      const user = JSON.parse(localStorage.getItem('user'));
      dataClient.Search.forEach((element) => {
        if (user._id === element) {
          blockData = false;
        }
      });
      setUpdateC(blockData);
      setConfirmContract(blockData);
    }
  }, [dataClient]);
  return (
    <div className="container" id="FORM-REF" ref={divRef}>
      <div className="row">
        <div className="col-12 col-md-6 margin-filter">
          <div className="mt-xxl">
            <h2>Detalles del contrato</h2>
          </div>
        </div>
        <form onSubmit={handleSubmitStep}>
          <div className="">
            <div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="noContrato" className="label mt-m">
                    Número de contrato
                    <input
                      onChange={handleChange('noContrato')}
                      value={noContrato}
                      id="noContrato"
                      type="number"
                      className={`input ${submitted && errors && errors.noContrato && 'has-error'}`}
                    />
                    {submitted && errors && errors.noContrato && <small className="caption-error">{errors.noContrato}</small>}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12">&nbsp;</div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="content-buttom">&nbsp;</div>
                  <h2>Detalles del pago</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="precioVenta" className="label mt-m">
                    Total de la venta
                    <input
                      value={formatMoney.format(precioVenta)}
                      id="precioVenta"
                      type="text"
                      disabled
                      className={`input not-border ${submitted && errors && errors.precioVenta && 'has-error'}`}
                    />
                    {submitted && errors && errors.precioVenta && <small className="caption-error">{errors.precioVenta}</small>}
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="formaPago" className="label labels mt-m">
                    Tipo de pago
                    <br />
                    <button type="button" onClick={() => setFieldValue('formaPago', 'mensualidades')} className={`btn-edc ${formaPago === 'mensualidades' ? 'active' : ''} genderM content-half`}>Mensualidades</button>
                    <button type="button" onClick={() => setFieldValue('formaPago', 'contado')} className={`btn-edc ${formaPago === 'contado' ? 'active' : ''} genderF content-half`}>Contado</button>
                    {submitted && errors && errors.formaPago && <small className="caption-error">{errors.formaPago}</small>}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="anticipo" className="label mt-m">
                    Anticipo
                    <CurrencyInput
                      id="anticipo"
                      name="anticipo"
                      placeholder=""
                      className={`input ${submitted && errors.sucursales && 'has-error'}`}
                      defaultValue={200}
                      decimalsLimit={2}
                      value={anticipo}
                      prefix="$"
                      pattern="[$]+[0-9]+([\.,][0-9]+)?"
                      allowNegativeValue={false}
                      onValueChange={(e) => _handleChange(e, 'anticipo')}
                      disabled={formaPago === 'contado' ? 'disabled' : ''}
                    />
                    {submitted && errors.sucursales && (
                      <small className="caption-error">
                        {errors.sucursales}
                      </small>
                    )}
                    {submitted && errors && errors.anticipo && <small className="caption-error">{errors.anticipo}</small>}
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="mensualidad" className="label mt-m">
                    Mensualidades
                    <select
                      type="mensualidad"
                      value={mensualidad}
                      disabled={formaPago === 'contado' ? 'disabled' : ''}
                      onChange={handleChange('mensualidad')}
                    >
                      <option value="">Seleccione una opción</option>
                      {recalcMont && pagoMeses.map((items) => (
                        <option key={items.value} name={items.value} value={items.value}>
                          {items.label}
                          {' '}
                          {CalcMonths(items.value, precioVenta)}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="diaPago" className="label mt-m">
                    Fecha de pago
                    <select
                      type="diaPago"
                      value={diaPago}
                      disabled={formaPago === 'contado' ? 'disabled' : ''}
                      onChange={handleChange('diaPago')}
                    >
                      <option value="">Seleccione una opción</option>
                      {pagoDia.map((items) => (
                        <option key={items.value} name={items.value} value={items.value}>
                          {items.label}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12">&nbsp;</div>
                <div className="col-12">
                  <div className="content-buttom">&nbsp;</div>
                  <h2>
                    Comentarios
                    <br />
                    y anotaciones
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <label htmlFor="comentarios" className="label mt-m">
                    Anotaciones
                    <textarea
                      onChange={handleChange('comentarios')}
                      value={comentarios}
                      id="comentarios"
                      className={`input sizein ${submitted && errors && errors.comentarios && 'has-error'}`}
                    />
                    {submitted && errors && errors.comentarios && <small className="caption-error">{errors.comentarios}</small>}
                    <label htmlFor="sample" className="label font-sm ">
                      (Opcional)
                    </label>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="col-12 col-md-12">
                  &nbsp;
                </div>
                <div className="col-12 col-md-12">
                  <button type="button" className="button-secondary p-r m-btn-prof" onClick={returStep}>
                    Regresar
                  </button>
                  <button className="button mt-m" type="submit">
                    Continuar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormStept3;
